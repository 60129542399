import { object, string, array, boolean } from 'zod'

import type { CollectionShelfv2Props } from './CollectionShelfV2/CollectionShelfV2'

export function validateProps(props: CollectionShelfv2Props) {
  try {
    const collectionShelfProps = object({
      enableSession: boolean(),
      title: string(),
      collections: array(
        object({
          engine: string(),
          id: string(),
          text: string(),
        })
      ).max(3),
    })

    collectionShelfProps.parse(props)

    return true
  } catch (error) {
    console.error('Invalid CollectionShelf data')

    return false
  }
}

export const COLLECTION_API =
  'https://decathlonstore.myvtex.com/_v3/api/decathlon-search/product/collection'

export const itemSizeAndQttyToScroll = (screenWidth: number) => {
  let obj

  if (screenWidth > 1024) {
    obj = { width: 324, qtty: 4 }
  } else if (screenWidth > 768) {
    obj = { width: 266, qtty: 3 }
  } else {
    obj = { width: 166, qtty: 2 }
  }

  return obj
}
