import { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { checkEnviromentIsB2B } from 'src/utils/checkEnviroment'
import { useProductLink } from 'src/sdk/product/useProductLinkV2'
import SKUSelectorBoughtTogether from 'src/components/product/SkuSelector/SKUSelectorBoughtTogether'
import type { ActiveItem } from 'src/components/ShelfLinx/ShelfBoughtTogether/types'
import { useMobile } from 'src/hooks/useMobile'

import type { Product as ProductRestructure } from '../types/product'
import type { ProductItem } from '../types/productItem'
import ProductCardHeader from './ProductCardHeader'
import ProductCardPrice from './ProductCardPrice'
import ProductBrand from './ProductBrand'
import ProductFlag from './ProductFlag'
import ProductQuantitySKU from './ProductQuantitySKU'

interface ProductCardProps {
  product: ProductRestructure
  UseModeList: boolean
  drag?: boolean
  index: number
  shelfRef?: string
  shelfEng?: string
  viewSelectVariation?: boolean
  handleChangedItemSKU?: (item: ActiveItem | undefined) => void
  isShelfBoughtTogether?: boolean
}

export default function ProductCard({
  product,
  UseModeList,
  viewSelectVariation = false,
  index,
  shelfRef,
  shelfEng,
  drag,
  handleChangedItemSKU,
  isShelfBoughtTogether = false,
}: ProductCardProps) {
  const { isMobile } = useMobile()

  const [viewOptionsCard, setViewOptionsCard] = useState(false)
  const [skuSelected, setSkuSelected] = useState<ProductItem>(product.items[0])
  const [imagesList, setImagesList] = useState(product.items[0].images)
  const [SkuId, setSkuId] = useState<string | undefined>()

  const {
    linkText,
    productName,
    items: [
      {
        sellerDefault: {
          sellerOffer: { cashback },
        },
      },
    ],
  } = product

  const styledModeList = UseModeList
    ? `md:flex-col ${!isMobile && 'min-h-[500px]'}`
    : `flex-col ${!isMobile ? 'min-h-[500px]' : 'min-h-[350px]'}`

  const isB2B = checkEnviromentIsB2B()

  const colors = new Set(product.items.map((item) => item.Cor))
  const colorsArray = Array.from(colors).flat()
  const uniqueColorsCount = new Set(colorsArray).size

  const showSkuSelector =
    isShelfBoughtTogether || (viewOptionsCard && !isMobile)

  const showProductName =
    isShelfBoughtTogether || !viewOptionsCard || uniqueColorsCount <= 1

  const showFlag =
    !isShelfBoughtTogether && isMobile && skuSelected.flags?.length > 0

  useEffect(() => {
    const newSku = SkuId
      ? product.items.find((item) => item.itemId === SkuId)
      : product.items[0]

    setImagesList(
      newSku !== undefined ? newSku.images : product.items[0].images
    )

    if (!newSku) {
      return
    }

    setSkuSelected(newSku)
    setImagesList(newSku.images)
  }, [SkuId, product])

  const handleChangedSku = (sku: ActiveItem) => {
    const newSku = product.items.find((item) => item.itemId === sku.itemId)

    if (!newSku) {
      return
    }

    setSkuSelected(newSku)
    setImagesList(newSku.images)
    setSkuId(sku.itemId)

    if (handleChangedItemSKU) {
      const newActiveItem: ActiveItem = {
        itemId: newSku.itemId,
        listedPrice: newSku.offer.listPrice,
        price: newSku.offer.price,
        name: newSku.name,
        quantity: newSku.sellerDefault.sellerOffer.quantity,
        sellerId: newSku.sellerDefault.sellerId,
      }

      handleChangedItemSKU(newActiveItem)
    }
  }

  const productLink = useProductLink({
    product,
    currentSku: skuSelected,
    slug: `${linkText}-${skuSelected.itemId}`,
    index,
    shelfRef,
    shelfEng,
  })

  const handleMouse = () => {
    if (!isMobile) {
      setViewOptionsCard(true)
    }
  }

  const handleMouseOut = () => {
    setViewOptionsCard(false)
  }

  return (
    <div
      className={`product-card-v2 flex justify-start ${styledModeList}`}
      onMouseEnter={handleMouse}
      onMouseLeave={handleMouseOut}
    >
      <ProductCardHeader
        product={product}
        currentSku={skuSelected}
        viewOptions={viewOptionsCard}
        isDesktop={!isMobile}
        link={productLink}
        modeList={UseModeList}
        Images={imagesList}
        drag={drag}
        isShelfBoughtTogether={isShelfBoughtTogether}
      />

      {showSkuSelector && (
        <SKUSelectorBoughtTogether
          productItems={product}
          currentSku={skuSelected}
          handleChangedItemSKU={handleChangedSku}
          viewSelectVariation={viewSelectVariation}
        />
      )}

      <div
        className={`${
          !UseModeList ? 'w-full' : 'w-3/5 ml-[16px] md:ml-0 md:w-full'
        }`}
      >
        <Link {...productLink}>
          {showProductName && (
            <>
              {showFlag && (
                <ProductFlag
                  flag={skuSelected.flags[0]}
                  className="text-[10px]"
                />
              )}

              <ProductBrand brand={product.brand} />

              <div className=" font-inter mt-[0px] font-regular text-[#101010] text-[12px] md:text-[16px] leading-2 md:mt-[8px] line-clamp-2">
                {productName}
              </div>
            </>
          )}
          <ProductCardPrice product={product} currentSku={skuSelected} />

          {skuSelected.isAvailable && cashback && !isB2B && (
            <div className="font-inter text-[#006F43] text-[10px] md:text-[12px] font-medium uppercase leading-12 tracking-wide mt-[8px] md:mt-[16px]">
              {cashback}
            </div>
          )}

          {!skuSelected.isAvailable && (
            <p className="font-inter text-[#101010] text-[14px] leading-12">
              Produto indisponível
            </p>
          )}

          {isMobile && product?.skuSpecifications[0]?.values.length > 1 && (
            <ProductQuantitySKU SkuSpecification={product.skuSpecifications} />
          )}
        </Link>
      </div>
    </div>
  )
}
