import { useCallback } from 'react'
import {
  makeProductClickEvent,
  sendEvent,
} from 'src/utils/restructure/analytics'
import { Statsig } from 'statsig-react'
import { useMobile } from 'src/hooks/useMobile'

import { useLinxProductClick } from '../linx/hooks/useLinxProductClick'
import { sendSearchClickEvent } from '../analytics/platform/intelligentSearch'

export type ProductLinkOptions = {
  index: number
  product: any
  currentSku: any
  slug: string
  shelfRef?: string
  shelfEng?: string
}

export const useProductLink = ({
  index,
  product,
  currentSku,
  slug,
  shelfRef,
  shelfEng,
}: ProductLinkOptions) => {
  const { isMobile } = useMobile()
  const { sendProductClickEvent } = useLinxProductClick()

  const logEvent = useCallback((eventName: string, params: any) => {
    Statsig.logEvent(eventName, null, params)
  }, [])

  const logStatsigEvents = useCallback(() => {
    const deviceType = { device: isMobile ? 'mobile' : 'desktop' }

    if (window.location.href.includes('pesquisa')) {
      logEvent('interagiu_card_da_PLP', deviceType)
    }

    if (shelfRef) {
      logEvent('interacao_vitrine', {
        ...deviceType,
        'motor vitrine': shelfEng ?? '',
        label: shelfRef,
      })
    }
  }, [isMobile, shelfEng, shelfRef, logEvent])

  const triggerProductEvents = useCallback(() => {
    if (product.clickUrl) {
      sendProductClickEvent({ clickUrl: product.clickUrl })
    }

    const productClick = makeProductClickEvent(product, product.items[0], {
      listName: shelfRef ?? 'product card',
      dimension29: shelfEng ?? '',
    })

    sendEvent(productClick)

    sendSearchClickEvent({
      name: 'search_select_item',
      params: {
        url: window.location.href,
        position: index,
        productId: product.productId,
      },
    })
  }, [index, product, shelfEng, sendProductClickEvent, shelfRef])

  const onClick = useCallback(() => {
    logStatsigEvents()
    triggerProductEvents()
  }, [logStatsigEvents, triggerProductEvents])

  if (currentSku.isKit) {
    return {
      to: `/${slug}/kit`,
      onClick,
      'data-testid': 'product-link',
    }
  }

  return {
    to: `/${slug}/p`,
    onClick,
    'data-testid': 'product-link',
  }
}
