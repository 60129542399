import { checkEnviromentIsB2B } from 'src/utils/checkEnviroment'

type Props = {
  flag: {
    value: string
    color: string
    textColor: string
  }
  className: string
}

function ProductFlag({ flag, className }: Props) {
  const isB2B = checkEnviromentIsB2B()

  if (isB2B && flag?.value === 'Oferta Clube Decathlon') {
    return null
  }

  return (
    <div
      className={`rounded py-0.5 px-2 leading-none font-roboto font-normal w-fit ${className}`}
      style={{ backgroundColor: flag?.color, color: flag?.textColor }}
    >
      {flag?.value ?? ''}
    </div>
  )
}

export default ProductFlag
