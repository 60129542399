import type { Product } from '../types/product'
import type { ProductItem } from '../types/productItem'

type ProductCardPriceProps = {
  product: Product
  currentSku: ProductItem
}

const formatPrice = (price: number) => {
  const diffPrice = String(price).split('.')

  let cents: string
  let realPrice: string

  if (!diffPrice[1]) {
    cents = '00'
  } else if (diffPrice[1] && diffPrice[1].length === 1) {
    cents = `${diffPrice[1]}0`
  } else {
    cents = diffPrice[1]
  }

  if (diffPrice[0].length === 4) {
    realPrice = `${diffPrice[0].slice(0, 1)}.${diffPrice[0].slice(1)}`
  } else if (diffPrice[0].length === 5) {
    realPrice = `${diffPrice[0].slice(0, 2)}.${diffPrice[0].slice(2)}`
  } else if (diffPrice[0].length === 6) {
    realPrice = `${diffPrice[0].slice(0, 3)}.${diffPrice[0].slice(3)}`
  } else {
    realPrice = diffPrice[0]
  }

  return `R$ ${realPrice},${cents}`
}

const ProductCardPrice = ({ currentSku }: ProductCardPriceProps) => {
  return (
    <div className="product-card__content--price flex gap-2 flex-wrap mt-2 sm:mt-4">
      <p className="font-inter text-[14px] md:text-[20px] text-[#101010] font-normal">
        {formatPrice(currentSku?.offer?.price)}
      </p>
      <p
        className={`font-inter font-regular flex text-[14px] items-center text-[#616161] line-through ${
          currentSku?.offer?.discountPercentage > 0 ? 'block' : 'hidden'
        }`}
      >
        {formatPrice(currentSku?.offer?.listPrice)}
      </p>
    </div>
  )
}

export default ProductCardPrice
