import type { SkuSpecification } from '../types/product'

interface ProductCardProps {
  SkuSpecification: SkuSpecification[]
}

export default function ProductQuantitySKU({
  SkuSpecification,
}: ProductCardProps) {
  const titleSku = 'cores'

  return (
    <span className="font-inter text-[12px] text-[#616161] font-regular leading-6">
      {`${SkuSpecification[0]?.values?.length} ${titleSku}`}
    </span>
  )
}
