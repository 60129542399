import { checkEnviromentIsB2B } from 'src/utils/checkEnviroment'

import { B2C } from './B2C-Config'
import { B2B } from './B2B-Config'

export const getStoreConfig = () => {
  const isB2B = checkEnviromentIsB2B()
  const configB2C = B2C
  const configB2B = B2B

  if (isB2B) {
    return configB2B
  }

  return configB2C
}
