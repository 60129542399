interface Props {
  width: string
  height: string
}

const WishlistFilledIcon = (props: Props) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9501 2.24805C16.1291 2.24805 15.3161 2.4098 14.5577 2.72408C13.7992 3.03836 13.1101 3.49899 12.5297 4.07967L12.0001 4.60921L11.4704 4.07954C10.2981 2.9072 8.70806 2.24858 7.05012 2.24858C5.39217 2.24858 3.80213 2.9072 2.62979 4.07954C1.45744 5.25189 0.798828 6.84193 0.798828 8.49987C0.798828 10.1578 1.45744 11.7479 2.62979 12.9202L11.4698 21.7602C11.7627 22.0531 12.2376 22.0531 12.5304 21.7602L21.3704 12.9202C21.9511 12.3398 22.4117 11.6507 22.7259 10.8923C23.0402 10.1338 23.2019 9.32088 23.2019 8.49987C23.2019 7.67886 23.0402 6.8659 22.7259 6.10742C22.4117 5.34906 21.9511 4.66002 21.3706 4.07967C20.7902 3.49899 20.101 3.03836 19.3426 2.72408C18.5841 2.40981 17.7711 2.24805 16.9501 2.24805Z"
      fill="black"
    />
  </svg>
)

export default WishlistFilledIcon
