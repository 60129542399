interface Props {
  width: string
  height: string
}

const WishlistEmtpyIcon = (props: Props) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.70479 1.81613C10.2104 1.60662 10.7524 1.49878 11.2998 1.49878C11.8471 1.49878 12.3891 1.60662 12.8947 1.81613C13.4004 2.02565 13.8598 2.33274 14.2467 2.71986C14.6338 3.10676 14.9408 3.56612 15.1503 4.0717C15.3598 4.57735 15.4676 5.11932 15.4676 5.66666C15.4676 6.214 15.3598 6.75598 15.1503 7.26163C14.9408 7.76724 14.6337 8.22663 14.2466 8.61355C14.2466 8.61358 14.2467 8.61352 14.2466 8.61355L8.35331 14.5069C8.15805 14.7021 7.84146 14.7021 7.6462 14.5069L1.75287 8.61355C0.971304 7.83199 0.532227 6.77196 0.532227 5.66666C0.532227 4.56137 0.971304 3.50134 1.75287 2.71978C2.53443 1.93821 3.59446 1.49914 4.69975 1.49914C5.80505 1.49914 6.86508 1.93821 7.64664 2.71978L7.99975 3.07289L8.35278 2.71986C8.73972 2.33274 9.19914 2.02565 9.70479 1.81613ZM11.2998 2.49878C10.8838 2.49878 10.4719 2.58074 10.0876 2.73997C9.70329 2.8992 9.35413 3.13259 9.06006 3.4268L8.35331 4.13355C8.15805 4.32881 7.84146 4.32881 7.6462 4.13355L6.93953 3.42688C6.34551 2.83286 5.53983 2.49914 4.69975 2.49914C3.85967 2.49914 3.054 2.83286 2.45997 3.42688C1.86595 4.02091 1.53223 4.82658 1.53223 5.66666C1.53223 6.50674 1.86595 7.31242 2.45997 7.90644L7.99975 13.4462L13.5395 7.90644C13.8337 7.61237 14.0672 7.26313 14.2264 6.87884C14.3857 6.49454 14.4676 6.08264 14.4676 5.66666C14.4676 5.25069 14.3857 4.83878 14.2264 4.45449C14.0672 4.07019 13.8338 3.72104 13.5396 3.42697C13.2455 3.13276 12.8962 2.8992 12.5119 2.73997C12.1276 2.58074 11.7157 2.49878 11.2998 2.49878Z"
      fill="#101010"
    />
  </svg>
)

export default WishlistEmtpyIcon
