import { useContext, useMemo, useState } from 'react'
import { ProductContext } from 'src/components/restructure/product/contexts'
import { useWishlistContext } from 'src/components/contexts/wishlist-context'
import useWishlist from 'src/hooks/useWishlist'
import { useAccountContext } from 'src/components/account/context'
import type { Product } from 'src/components/restructure/product/types/product'
import WishlistFilledIcon from 'src/components/Icons/WhistlistSelectIcon'
import WishlistEmtpyIcon from 'src/components/Icons/WhistlistIcon'
import { useMobile } from 'src/hooks/useMobile'

import { loginUrl } from '../../../../../../../store.config'

interface WishlistButtonProps {
  product?: Product
  desktopSize: string
  mobileSize: string
  variant?: 'pdp' | 'card'
}

export function WishListButton({
  product: productProps,
  desktopSize,
  mobileSize,
  variant,
}: WishlistButtonProps) {
  const { memberAuthToken } = useAccountContext()
  const { product: productData } = useContext(ProductContext)
  const [isInWishlist, setIsInWishlist] = useState(false)
  const { userWishlist, setUserWishlist, setWishlistId } = useWishlistContext()
  const { isMobile } = useMobile()

  const product = productProps ?? productData

  const {
    isEmptyWishlist,
    getWishlist,
    addProductToWishlist,
    removeProductFromWishlist,
  } = useWishlist()

  const refreshWishlist = () => {
    getWishlist().then((wishlistData) => {
      setUserWishlist(wishlistData?.data?.products ?? [])
      setWishlistId(wishlistData?.data?.id)

      return wishlistData?.data?.products
    })
  }

  const handleButtonClick = () => {
    const currentPageUrl = window?.location?.pathname ?? ''

    if (!memberAuthToken) {
      window.location.href = `${loginUrl}?returnUrl=https://www.decathlon.com.br${currentPageUrl}`
    }

    if (isInWishlist === true) {
      removeProductFromWishlist(product?.productId).then(refreshWishlist)
      setIsInWishlist(false)

      return
    }

    addProductToWishlist(product?.productId).then(refreshWishlist)
    setIsInWishlist(true)
  }

  const wishListIcon = useMemo(() => {
    const wishlistItemId =
      userWishlist?.filter(
        ({ productId }: any) => productId === product?.productId
      ) ?? []

    wishlistItemId.length > 0 ? setIsInWishlist(true) : setIsInWishlist(false)

    return isInWishlist ? (
      <WishlistFilledIcon
        aria-label="Botão para remover produto da lista de favoritos"
        data-testid="colorful-wish-list"
        width={isMobile ? mobileSize : desktopSize}
        height={isMobile ? mobileSize : desktopSize}
      />
    ) : (
      <WishlistEmtpyIcon
        aria-label="Botão para adicionar produto na lista de favoritos"
        data-testid="wish-list-icon"
        width={isMobile ? mobileSize : desktopSize}
        height={isMobile ? mobileSize : desktopSize}
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userWishlist, isEmptyWishlist, isInWishlist, product?.productId])

  return (
    <button
      className={
        variant === 'pdp'
          ? 'w-[48px] h-[48px] flex items-center justify-center'
          : ''
      }
      onClick={handleButtonClick}
      aria-label="add or remove item of wishlist"
    >
      {wishListIcon}
    </button>
  )
}
