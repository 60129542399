import Skeleton from 'react-loading-skeleton'
import Section from 'src/components/sections/Section'
import 'src/components/sections/ProductShelf/product-shelf.scss'
import 'react-loading-skeleton/dist/skeleton.css'
import { useMobile } from 'src/hooks/useMobile'
import type { PropsWithChildren } from 'react'

interface Props {
  loading?: boolean
  title?: boolean
}

function ProductShelfSkeleton({
  children,
  loading = true,
  title = true,
}: PropsWithChildren<Props>) {
  const { screenWidth } = useMobile()

  const quantityOfShelf = screenWidth > 900 ? 4 : screenWidth > 600 ? 3 : 2

  return loading ? (
    <Section className="layout__content layout__section shelf-section p-0 m-0">
      {title && (
        <h2 className="mt-11 mb-8 max-w-xs">
          <Skeleton />
        </h2>
      )}
      <ul
        data-product-shelf
        className="layout__content !gap-x-2 sm:!gap-x-6 p-0"
      >
        {Array.from({ length: quantityOfShelf }, (_, index) => (
          <li key={String(index)}>
            <Skeleton height={screenWidth > 600 ? 455 : 350} />
          </li>
        ))}
      </ul>
    </Section>
  ) : (
    <>{children}</>
  )
}

export default ProductShelfSkeleton
