import { useRef, useState, useEffect, useCallback } from 'react'
import { Statsig } from 'statsig-react'
import { useMobile } from 'src/hooks/useMobile'
import { useDebounce } from 'src/hooks/useDebounce'
import axios from 'axios'
import {
  makeViewItemListEvent,
  makeViewListImpressionEvent,
  sendEvent,
} from 'src/utils/restructure/analytics'

import ProductCard from '../../ProductCard'
import type { Product } from '../../types/product'
import './customSlider.css'
import ShelfV2NavButtons from './ShelfV2NavButtons'
import { itemSizeAndQttyToScroll } from '../shelvesUtils'

interface ShelfV2Props extends React.HTMLAttributes<HTMLDivElement> {
  products: Product[]
  impressionUrl?: string
  title: string
  engine?: string
  tabs?: Array<{ text: string; id: string }>
  activeCollection?: string
  onTabClick?: React.Dispatch<React.SetStateAction<string>>
}

const ShelfV2 = ({
  products,
  title,
  impressionUrl,
  tabs,
  onTabClick,
  activeCollection,
  engine,
  ...rest
}: ShelfV2Props) => {
  const observer = useRef<HTMLPreElement>(null)
  const { isMobile, screenWidth } = useMobile()
  const listRef = useRef<HTMLUListElement | null>(null)
  const listWrapperRef = useRef<HTMLDivElement | null>(null)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [navState, setNavState] = useState<{
    prev: boolean
    next: boolean
  }>({ prev: false, next: true })

  const resizeObserver = useRef<ResizeObserver | null>(null)
  const [thumbWidth, setThumbWidth] = useState(20)
  const thumbRef = useRef<HTMLDivElement>(null)
  const isDraggingRef = useRef(false)
  const [isDragging, setIsDragging] = useState(false)
  const startXRef = useRef<number>(0)
  const initialScrollLeftRef = useRef<number>(0)
  const SPEED_MULTIPLIER = -2

  const handleThumbMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!isDraggingRef.current || !thumbRef.current || !listRef.current) {
        return
      }

      const deltaX = e.clientX - startXRef.current
      const wrapper = listRef.current
      const scrollWidth = wrapper.scrollWidth - wrapper.offsetWidth

      const newScrollLeft = Math.min(
        Math.max(initialScrollLeftRef.current - deltaX * SPEED_MULTIPLIER, 0),
        scrollWidth
      )

      wrapper.scrollLeft = newScrollLeft
    },
    [listRef, SPEED_MULTIPLIER]
  )

  const handleThumbMouseUp = useCallback(() => {
    if (!thumbRef.current || !listRef.current) {
      return
    }

    isDraggingRef.current = false
    setIsDragging(false)
    document.removeEventListener('mousemove', handleThumbMouseMove)
    document.removeEventListener('mouseup', handleThumbMouseUp)
  }, [handleThumbMouseMove, listRef])

  const handleThumbMouseDown = (e: React.MouseEvent) => {
    e.preventDefault()
    if (!thumbRef.current || !listRef.current) {
      return
    }

    setIsDragging(true)
    isDraggingRef.current = true
    startXRef.current = e.clientX
    initialScrollLeftRef.current = listRef.current.scrollLeft

    document.addEventListener('mousemove', handleThumbMouseMove)
    document.addEventListener('mouseup', handleThumbMouseUp)
  }

  const debouncedScroll = useDebounce(sendStatSigEvent, 1000)

  function sendStatSigEvent() {
    Statsig.logEvent('interacao_seta_vitrine', null, {
      device_category: isMobile ? 'mobile' : 'desktop',
      label: title,
      engine: engine ?? '',
    })
  }

  const onScroll = () => {
    if (!listRef?.current || !listWrapperRef?.current) {
      return
    }

    debouncedScroll()
    const wrapperWidth = listWrapperRef.current.getBoundingClientRect().width

    const { scrollWidth, scrollLeft } = listRef.current

    setNavState((old) => ({
      ...old,
      prev: scrollLeft !== 0,
      next: scrollWidth - wrapperWidth - scrollLeft !== 0,
    }))

    setCurrentPage(
      products.length -
        Math.round(
          (scrollWidth - scrollLeft) /
            itemSizeAndQttyToScroll(screenWidth).width
        )
    )
  }

  const moveTo = (index: number) => {
    if (!listRef?.current || !listWrapperRef?.current) {
      return
    }

    listRef.current.scrollLeft =
      index * itemSizeAndQttyToScroll(screenWidth).width
  }

  const onPrevClick = (): void => {
    if (!listRef?.current || !listWrapperRef?.current) {
      return
    }

    moveTo(currentPage - itemSizeAndQttyToScroll(screenWidth).qtty)
  }

  const onNextClick = (): void => {
    if (!listRef?.current || !listWrapperRef?.current) {
      return
    }

    moveTo(currentPage + itemSizeAndQttyToScroll(screenWidth).qtty)
  }

  useEffect(() => {
    if (!observer.current) {
      return () => {}
    }

    const intersectionObserver = new IntersectionObserver((entries) => {
      if (!entries.some((entry) => entry.isIntersecting)) {
        return
      }

      const items = products.map((product: Product) => ({
        product,
        productItem: product.items[0],
        listName: `vitrine - ${title}`,
        listId: 'NA',
      }))

      const viewListImpressionEvent = makeViewListImpressionEvent(items, {
        listName: `vitrine - ${title}`,
        dimension29: engine ?? '',
      })

      // TODO: Unify the two products types
      const viewItemListEvent = makeViewItemListEvent(items as any)

      sendEvent(viewListImpressionEvent)
      sendEvent(viewItemListEvent)

      if (impressionUrl) {
        axios.get(impressionUrl)
      }
    })

    intersectionObserver.observe(observer.current)

    return () => {
      intersectionObserver.disconnect()
    }
  }, [impressionUrl, title, products, engine])

  useEffect(() => {
    const handleResize = () => {
      if (!listRef.current) {
        return
      }

      const visibleWidth = listRef.current.offsetWidth
      const contentWidth = listRef.current.scrollWidth

      setThumbWidth((visibleWidth / contentWidth) * 100)
    }

    if (!listRef.current) {
      return () => {}
    }

    const ref = listRef.current

    resizeObserver.current = new ResizeObserver(() => {
      handleResize()
    })

    resizeObserver.current?.observe(ref)

    return () => {
      resizeObserver.current?.unobserve(ref)
    }
  }, [])

  useEffect(() => {
    return () => {
      document.removeEventListener('mousemove', handleThumbMouseMove)
      document.removeEventListener('mouseup', handleThumbMouseUp)
    }
  }, [handleThumbMouseMove, handleThumbMouseUp])

  useEffect(() => {
    if (!listRef.current) {
      return
    }

    const wrapper = listRef.current

    wrapper.style.scrollSnapType = isDragging ? 'none' : 'x mandatory'
    wrapper.style.scrollBehavior = isDragging ? 'unset' : 'smooth'
  }, [listRef, isDragging])

  if (!products.length) {
    return null
  }

  return (
    <>
      <pre ref={observer} />
      <div
        {...rest}
        className="shelf-v2-wrapper max-w-[1280px] mx-auto my-[40px] sm:my-[80px] pl-4 sm:pl-5 md:pl-0"
      >
        <div className="flex flex-wrap justify-between items-center  pr-4 sm:pr-5 md:pr-0 mb-8 sm:mb-10">
          <h2 className="text-2xl sm:text-[32px] font-medium font-inter">
            {title}
          </h2>

          {!isMobile && products.length > 4 && (
            <ShelfV2NavButtons {...{ onNextClick, onPrevClick, navState }} />
          )}

          {tabs?.length && (
            <div className="w-full flex gap-2 mt-5">
              {tabs.map((tab) => {
                return (
                  tab?.text && (
                    <button
                      className={`py-2 px-3 h-[40px] rounded-round border text-sm font-normal font-inter ${
                        tab.id === activeCollection
                          ? 'border-restructure-background-action-1 text-restructure-action'
                          : 'text-tertiary border-primary  '
                      }`}
                      onClick={() => {
                        typeof onTabClick === 'function' && onTabClick(tab.id)
                        moveTo(0)
                      }}
                      key={tab.id}
                    >
                      {tab.text}
                    </button>
                  )
                )
              })}
            </div>
          )}
        </div>

        <div className="shelf-v2-carousel">
          <div ref={listWrapperRef} className="shelf-v2-carousel">
            <ul
              ref={listRef}
              className="shelf-v2-carouselList"
              onScroll={onScroll}
            >
              {products.map((product, i) => (
                <li
                  key={`product-$${i}`}
                  className="shelf-v2-carouselImageWrapper"
                >
                  <ProductCard
                    UseModeList={false}
                    index={i}
                    drag={!isMobile}
                    product={product}
                    shelfRef={`vitrine - ${title}`}
                    shelfEng={engine}
                  />
                </li>
              ))}
            </ul>
          </div>
          <div
            className="shelf-v2-scrollbar"
            style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
          >
            <div className="shelf-v2-scrollbar-track" />
            <div
              onMouseDown={handleThumbMouseDown}
              style={{
                left: listRef.current
                  ? `${
                      (listRef.current.scrollLeft /
                        listRef.current.scrollWidth) *
                      100
                    }%`
                  : '0%',
                width: `${thumbWidth}%`,
              }}
              ref={thumbRef}
              className="shelf-v2-scrollbar-thumb"
              tabIndex={0}
              role="slider"
              aria-valuemin={0}
              aria-valuemax={100}
              aria-valuenow={
                listRef.current
                  ? (listRef.current.scrollLeft / listRef.current.scrollWidth) *
                    100
                  : 0
              }
              aria-label="scrollbar thumb"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ShelfV2
