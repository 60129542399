import { createContext, useContext, useEffect, useState } from 'react'
import type { Dispatch, SetStateAction } from 'react'
import type { ProductItem } from 'src/components/restructure/product/types/productItem'
import type {
  Product,
  SkuSpecification,
} from 'src/components/restructure/product/types/product'

import { getInitialItem } from '../../utils/get-initial-sku'
import type {
  Item,
  FreightSimulationData,
} from '../../common/FreightSimulation/useFreightSimulation'
import { useFreightSimulation } from '../../common/FreightSimulation/useFreightSimulation'

type ProductProviderProps = {
  children: React.ReactNode
  product: Product
  slug: string
}

type ProductContextProps = {
  product: Product
  currentSku: ProductItem
  initialSku: ProductItem
  specificationMustBeSelected: SkuSpecification | null
  setSpecificationMustBeSelected: Dispatch<
    React.SetStateAction<SkuSpecification | null>
  >
  setCurrentSku: Dispatch<SetStateAction<ProductItem>>
  deliveryIsAvailable: boolean
  setDeliveryIsAvailable: Dispatch<SetStateAction<boolean>>
  setFreightSimulation: (items: Item[], cep: string) => Promise<void>
  isLoadingFreightSimulation: boolean
  freightSimulationValue: FreightSimulationData | null
  triedToAdd: boolean
  setTriedToAdd: Dispatch<SetStateAction<boolean>>
}

export const ProductContext = createContext<ProductContextProps>(
  {} as ProductContextProps
)

export const ProductProvider = ({
  children,
  product,
  slug,
}: ProductProviderProps) => {
  const initialSku = getInitialItem(product.items, slug)
  const { getFreightSimulation } = useFreightSimulation()
  const [triedToAdd, setTriedToAdd] = useState(false)

  const [currentSku, setCurrentSku] = useState(initialSku)
  const [deliveryIsAvailable, setDeliveryIsAvailable] = useState(false)
  const [specificationMustBeSelected, setSpecificationMustBeSelected] =
    useState<SkuSpecification | null>(null)

  const [isLoadingFreightSimulation, setIsLoadingFreightSimulation] =
    useState(false)

  const [freightSimulationValue, setFreightSimulationValue] =
    useState<FreightSimulationData | null>(null)

  useEffect(() => {
    // to change current sku when product is changed
    setCurrentSku(getInitialItem(product.items, slug))
  }, [product, slug])

  const setFreightSimulation = async (items: Item[], cep: string) => {
    setIsLoadingFreightSimulation(true)

    const response = await getFreightSimulation(items, cep)

    const isDeliveryAvailable = response?.packages?.some(
      (item) => item?.deliveryTypes?.length > 0
    )

    setDeliveryIsAvailable(isDeliveryAvailable ?? false)
    setFreightSimulationValue(response)
    setIsLoadingFreightSimulation(false)
  }

  const productContextValue = {
    product,
    initialSku,
    currentSku,
    specificationMustBeSelected,
    setSpecificationMustBeSelected,
    setCurrentSku,
    deliveryIsAvailable,
    setDeliveryIsAvailable,
    setFreightSimulation,
    isLoadingFreightSimulation,
    freightSimulationValue,
    triedToAdd,
    setTriedToAdd,
  }

  return (
    <ProductContext.Provider value={productContextValue}>
      {children}
    </ProductContext.Provider>
  )
}

export const useProductPageContext = () => useContext(ProductContext)
