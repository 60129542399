type ShelfV2NavButtonsProps = {
  onPrevClick: () => void
  onNextClick: () => void
  navState: {
    prev: boolean
    next: boolean
  }
}

const ShelfV2NavButtons = ({
  onNextClick,
  onPrevClick,
  navState,
}: ShelfV2NavButtonsProps) => {
  return (
    <div className="flex gap-6">
      <button
        className="shelf-v2-controlButton flex items-center justify-center left  w-[48px] h-[48px] sm:w-[64px] sm:h-[64px] rounded-round border border-primary"
        onClick={onPrevClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.66663 16.0002C5.66663 15.448 6.11434 15.0002 6.66663 15.0002H25.3333C25.8856 15.0002 26.3333 15.448 26.3333 16.0002C26.3333 16.5525 25.8856 17.0002 25.3333 17.0002H6.66663C6.11434 17.0002 5.66663 16.5525 5.66663 16.0002Z"
            fill={navState.prev ? '  #15181B' : '#A4ADB7'}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.7071 5.95979C17.0976 6.35032 17.0976 6.98348 16.7071 7.37401L8.08084 16.0002L16.7071 24.6265C17.0976 25.017 17.0976 25.6502 16.7071 26.0407C16.3165 26.4312 15.6834 26.4312 15.2929 26.0407L5.95952 16.7073C5.56899 16.3168 5.56899 15.6837 5.95952 15.2931L15.2929 5.95979C15.6834 5.56927 16.3165 5.56927 16.7071 5.95979Z"
            fill={navState.prev ? '#15181B' : '#A4ADB7'}
          />
        </svg>
      </button>
      <button
        className="shelf-v2-controlButton flex items-center justify-center right w-[48px] h-[48px] sm:w-[64px] sm:h-[64px] rounded-round border border-primary"
        onClick={onNextClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.66663 16.0002C5.66663 15.448 6.11434 15.0002 6.66663 15.0002H25.3333C25.8856 15.0002 26.3333 15.448 26.3333 16.0002C26.3333 16.5525 25.8856 17.0002 25.3333 17.0002H6.66663C6.11434 17.0002 5.66663 16.5525 5.66663 16.0002Z"
            fill={navState.next ? '#15181B' : '#A4ADB7'}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.2929 5.95979C15.6834 5.56927 16.3166 5.56927 16.7071 5.95979L26.0404 15.2931C26.431 15.6837 26.431 16.3168 26.0404 16.7073L16.7071 26.0407C16.3166 26.4312 15.6834 26.4312 15.2929 26.0407C14.9024 25.6502 14.9024 25.017 15.2929 24.6265L23.9191 16.0002L15.2929 7.37401C14.9024 6.98348 14.9024 6.35032 15.2929 5.95979Z"
            fill={navState.next ? '#15181B' : '#A4ADB7'}
          />
        </svg>
      </button>
    </div>
  )
}

export default ShelfV2NavButtons
