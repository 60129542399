import { Image } from 'src/components/ui/Image'
import { Link } from 'gatsby'
import { useRef } from 'react'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'

import ProductFlag from './ProductFlag'
import type { Product } from '../types/product'
import type { ProductItem } from '../types/productItem'
import { WishListButton } from '../sections/Sidebar/ProductTitle/WishListButton'

type Image = {
  imageUrl: string
  imageText: string
}

interface ProductCardHeaderProps {
  product: Product
  currentSku: ProductItem
  viewOptions: boolean
  isDesktop: boolean
  link: ProductLink
  modeList: boolean
  drag?: boolean
  Images: Image[]
  isShelfBoughtTogether: boolean
}

interface ProductLink {
  to: string
  onClick: () => void
  'data-testid': string
}

function formatSrc(url: string, width: number, height: number) {
  const hasId = url?.includes('/arquivos/ids/')

  if (!hasId || !width || !height) {
    return url
  }

  const [id] = url.split('/arquivos/ids/')[1].split('/')[0].split('-')

  const formattedId = `${id}-${width}-${height}`

  return url.replace(id, formattedId)
}

export default function ProductCardHeader({
  product,
  currentSku,
  viewOptions,
  isDesktop,
  link,
  modeList,
  drag = true,
  Images,
  isShelfBoughtTogether,
}: ProductCardHeaderProps) {
  const splideRef = useRef<any>()

  const showProductFlag =
    isShelfBoughtTogether || (isDesktop && currentSku.flags?.length > 0)

  return (
    <div
      className={`${
        modeList && !isDesktop ? 'w-2/5 h-full' : 'relative mb-[12px]'
      }`}
    >
      <div>
        <Splide
          ref={splideRef}
          options={{
            pagination: false,
            gap: 10,
            arrows: viewOptions,
            drag,
          }}
          hasTrack={false}
        >
          <div className="relative">
            <SplideTrack>
              {Images.map((image, idx) => {
                return (
                  <SplideSlide
                    key={`${image?.imageUrl}-${image?.imageText}-${idx}-${product.items[0]?.itemId}`}
                    data-index={`element-splid-item${idx}`}
                  >
                    <div className="h-full bg-[#F7F8F9]">
                      <Link {...link}>
                        <Image
                          src={image?.imageUrl}
                          srcSet={formatSrc(image?.imageUrl, 350, 350)}
                          alt={product.productName}
                          title={product.productName}
                          width={302}
                          height={302}
                          loading="lazy"
                          className="w-full h-full object-contain mix-blend-multiply"
                          role="presentation"
                        />
                      </Link>
                    </div>
                  </SplideSlide>
                )
              })}
            </SplideTrack>
            {!isDesktop && (
              <div className="absolute top-[12px] right-[12px] text-sm z-10">
                <WishListButton
                  product={product}
                  mobileSize="16"
                  desktopSize="24"
                  variant="card"
                />
              </div>
            )}
          </div>
        </Splide>
      </div>

      {showProductFlag && (
        <ProductFlag
          flag={currentSku.flags[0]}
          className="absolute top-[16px] left-[16px] text-sm"
        />
      )}
      {isDesktop && (
        <div className="absolute top-[16px] right-[16px] text-sm z-10">
          <WishListButton
            product={product}
            mobileSize="16"
            desktopSize="24"
            variant="card"
          />
        </div>
      )}
    </div>
  )
}
